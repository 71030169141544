.datepicker--button, .datepicker--cell.-current- {
    color: #3fb2bf;
}

.btn-primary,
.datepicker--cell.-selected-, .datepicker--cell.-current-.-selected- {
	background: #3fb2bf !important;
	border: 0px !important;
}
.datepicker--cell.-selected-:hover, .datepicker--cell.-current-.-selected-:hover,
.btn-primary:hover, .btn-primary:active, .btn-primary:visited, .btn-primary:focus, .open > .dropdown-toggle.btn-primary  {
	background: #2f99a8 !important;
    border: 0px !important;
}

.indicator.blue::before, .indicator-right.blue::after {
	background: #2f99a8;
}

.btn-primary:focus, .btn-primary.focus {
	box-shadow: 0 0 0 0.2rem rgba(47, 153, 168, 0.6);
}

.form-control:focus, .btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
	box-shadow: 0 0 0 0.2rem rgba(63, 178, 191, 0.25);
}